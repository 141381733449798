<template>
  <base-detail-view
    :loading="loading"
    :title="
      this.type === 'create'
        ? 'Создание сводного отчета по ВКК'
        : 'Сводный отчет по ВКК'
    "
    :sub-title="'за 2022 год'"
    :isUnsaved="isUnsaved && valid"
    @onSave="save"
    @onRefresh="init"
  >
    <v-form v-model="valid" :readonly="readonly">
      <div class="block__content">
        <v-container>
          <!-- Inputs -->
        </v-container>
      </div>
    </v-form>
  </base-detail-view>
</template>

<script>
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import { mapActions } from "vuex";
import BaseDetailView from "../../layouts/BaseDetailView.vue";

export default {
  name: "view-UnwishedEventEdit",
  components: {
    BaseDetailView,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      // Api
      apiService: {},
      getDefaultObject: {},
      getObjectText: (obj, isNew) => (isNew ? "новое" : "22.06.2024"),
      loading: true,
      dataSource: {},
      valid: false,
      showCompleted: false,
    };
  },
  computed: {
    readOnly() {
      return false;
    },
  },
  watch: {},
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapActions([]),
    DataFromServerNormalize(data) {
      return data;
    },

    async save() {
      await this.baseSave();
    },
  },
};
</script>

<style lang="scss"></style>
